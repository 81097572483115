<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-card elevation="2" class="pa-6 mt-10">
          <v-card-title>
            Facilities
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="facilityList"
            :search="search"
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card elevation="2" class="pa-6 mt-10">
          <h2 class="font-weight-regular mb-4">Create facility</h2>
          <v-form v-model="valid" ref="form" @submit.prevent="onSubmit">
            <v-text-field
              v-model="facilityName"
              label="Facility Name"
              dense
              outlined
              required
              :rules="textRules"
            ></v-text-field>
            <v-textarea
              v-model="facilityDescription"
              label="Facility Description"
              dense
              outlined
              required
              :rules="textRules"
            ></v-textarea>
            <v-select
              v-model="facilityManager"
              :items="managers"
              label="Facility Manager"
              dense
              outlined
              required
              :rules="textRules"
            ></v-select>
            <v-btn
              class="ma-2 ml-0"
              outlined
              color="indigo"
              :disabled="!valid"
              @click="submit"
            >
              Add Facility
            </v-btn>
            <v-btn class="ma-2" outlined color="red" @click="reset">
              Clear
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "facilityID" },
        { text: "Name", value: "facilityName" },
        { text: "Manages By", value: "facilityManager" },
        { text: "Description", value: "facilityDescription" },
      ],
      facilityList: [],
      valid: false,
      textRules: [(v) => !!v || "This field is required"],
      managers: [],
    };
  },
  mounted() {
    this.getUsers();
    this.getFacilities();
  },
  methods: {
    getFacilities() {
      axios.get("http://localhost:8091/api/v1/facilities").then((response) => {
        this.facilityList = response.data;
      });
    },

    getUsers() {
      axios.get("http://localhost:8091/api/v1/users").then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          this.managers.push(response.data[i]["email"]);
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      this.$refs.form.validate();
      this.onSubmit();
    },
    onSubmit() {
      axios
        .post("http://localhost:8091/api/v1/facilities", {
          facilityName: this.facilityName,
          facilityDescription: this.facilityDescription,
          facilityManager: this.facilityManager,
        })
        .then((response) => {
          console.log(response);
          this.$refs.form.reset();
        });
    },
  },
};
</script>
